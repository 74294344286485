import { getEntriesWrapper } from '../../services/contentfulClient'

const RETREAT_SPA_EN = '3rys3DunbGA4wQok0i20Ae'
const RETREAT_SPA_IS = 'b0r1kDFTfpSkGs0anPxNC'

const BLUELAGOON_EN = '6egEdHMmmQmu0YsGm42Y84'
const BLUELAGOON_IS = 'QpW5vW1I1CffATtK6nVYI'

const SILICA_HOTEL_EN = '52a8BGrTlSAOcoScAmwgsG'
const SILICA_HOTEL_IS = '54Up8G6IAxR6f7oNYsV7eK'

const RETREAT_HOTEL_EN = '22atq3NcDOSyaeKMwY6EqE'
const RETREAT_HOTEL_IS = '1BDGxRfpRZObICF2jKY7iL'

const MOSS_RESTAURANT_EN = '7JuTDgleRRCKnUXvsv2lgz'
const MOSS_RESTAURANT_IS = '4MykwqJ8DHSO9D5MDUh89v'

const SPA_RESTAURANT_EN = '3PjXOJPAmWD3BPWuYxmzUg'
const SPA_RESTAURANT_IS = '30vZzuRehzto92rBHAXhH5'

const CAFE_EN = 'ES3aTJSveQg6gNMUGof9A'
const CAFE_IS = '6rBf4GkOGF6tNjy9bB7zYR'

const LAVA_RESTAURANT_EN = '42uJUZPFWarMLuiEEvvx1R'
const LAVA_RESTAURANT_IS = '2n9iNEwxp8kVzcFXz3hbkI'

const STORIES_EN = '6GAmiHmpHS83w7gkixfN6T'
const STORIES_IS = '6GAmiHmpHS83w7gkixfN6T'

const ABOUT_US_EN = '2K1eKESPXy0ZfQuVidB7jX'
const ABOUT_US_IS = '2K1eKESPXy0ZfQuVidB7jX'

const SUSTAINABILITY_EN = '1HqtZp6xsQHQzC6J8G9zA0'
const SUSTAINABILITY_IS = '1HqtZp6xsQHQzC6J8G9zA0'

const THE_WATER_EN = '8kjOtQT8MIQ0AfjNtp84s'
const THE_WATER_IS = '8kjOtQT8MIQ0AfjNtp84s'

const menus = {
  accommodation: {
    en: [SILICA_HOTEL_EN, RETREAT_HOTEL_EN],
    is: [SILICA_HOTEL_IS, RETREAT_HOTEL_IS],
  },
  dayVisit: {
    en: [BLUELAGOON_EN, RETREAT_SPA_EN],
    is: [BLUELAGOON_IS, RETREAT_SPA_IS],
  },
  restaurant: {
    en: [LAVA_RESTAURANT_EN, MOSS_RESTAURANT_EN, SPA_RESTAURANT_EN, CAFE_EN],
    is: [LAVA_RESTAURANT_IS, MOSS_RESTAURANT_IS, SPA_RESTAURANT_IS, CAFE_IS],
  },
  more: {
    en: [STORIES_EN, ABOUT_US_EN, SUSTAINABILITY_EN, THE_WATER_EN],
    is: [STORIES_IS, ABOUT_US_IS, SUSTAINABILITY_IS, THE_WATER_IS],
  },
  explore: {
    en: [BLUELAGOON_EN, RETREAT_SPA_EN, SILICA_HOTEL_EN, RETREAT_HOTEL_EN],
    is: [BLUELAGOON_IS, RETREAT_SPA_IS, SILICA_HOTEL_IS, RETREAT_HOTEL_IS],
  },
}

export const portalTitles = {
  en: {
    accommodation: {
      title: 'Accommodation',
      description:
        'Comfort and hospitality in the heart of an otherworldly landscape.',
    },
    dayVisit: {
      title: 'Day visit',
      description: 'Experience the radiant powers of geothermal seawater.',
    },
    explore: {
      title: 'Select your experience.',
      description: 'Select what you would like to experience.',
    },
  },
  is: {
    accommodation: {
      title: 'Gisting',
      description:
        'Njóttu hvíldar frá amstri hversdagsins í undursamlegu umhverfi.',
    },
    dayVisit: {
      title: 'Heimsókn í Bláa Lónið',
      description: 'Upplifðu eitt af 25 undrum veraldar.',
    },
    explore: {
      title: 'Select your experience.',
      description: 'Select what you would like to experience.',
    },
  },
}

export const fetchPortals = async (type, locale) => {
  const lang = locale || 'en'
  const ids = menus[type] ? menus[type][lang] : menus.explore[lang]

  const response = await getEntriesWrapper({
    content_type: 'experienceNavigationItem',
    include: 4,
  })

  // sort portal according to position in the ids array
  const temp = response.items.sort((a, b) => {
    return ids.indexOf(a.sys.id) - ids.indexOf(b.sys.id)
  })

  return ids.map(id => {
    return temp.find(item => item.sys.id === id).fields
  })
}

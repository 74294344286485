import React from 'react'

import { IPageMain } from 'bl-common/src/generated/contentful'

import { BreadcrumbFab } from '../../../BreadcrumbLink'
import { GradientSwitcher } from '../../../GradientSwitcher'
import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import SectionsRenderer from '../../SectionsRenderer'
import { ContentTemplateProps } from '../types'

const MainPage = ({
  content: {
    fields: { title, sections, customFooter },
  },
  metadata,
  config,
}: ContentTemplateProps<IPageMain>) => {
  const isTopicPage = config && config.fields.isTopicPage
  const hasSubnavigation = sections?.some(
    section => section.sys.contentType.sys.id === 'subnavigation'
  )

  const props = isTopicPage
    ? {
        breadcrumb: title,
        hideFooter: true,
        scrollHeader: true,
        hasAbsolutelyPositionedNavigation: true,
      }
    : {
        breadcrumb: title,
        footer: customFooter,
        config,
        hasSubnavigation,
      }
  return (
    <Page config={config} {...props}>
      <GradientSwitcher id="gradient">
        <SectionsRenderer sections={sections} pageConfig={config} />
        <Metadata metadata={metadata} title={title} />
        {isTopicPage && <BreadcrumbFab />}
      </GradientSwitcher>
    </Page>
  )
}

export default MainPage

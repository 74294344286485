import React, { ReactNode } from 'react'
import {
  IntlProvider,
  ReactIntlErrorCode,
  ResolvedIntlConfig,
} from 'react-intl'

import { sentryLogging } from 'bl-utils/src/sentryUtils'

interface Props {
  children: ReactNode
  messages: ResolvedIntlConfig['messages']
  locale: string
  defaultLocale?: string
}

export const IntlProviderWrapper = ({
  children,
  messages,
  locale,
  defaultLocale = 'en',
}: Props) => {
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages}
      onError={error => {
        // there is an error on 'is' and other locales that isn't relevant to what we're using this for
        // see discussion here https://github.com/formatjs/formatjs/issues/1776
        if (error.code === ReactIntlErrorCode.MISSING_DATA) {
          return
        }
        sentryLogging({
          message: 'INTL ERROR',
          extras: { error },
        })
      }}
    >
      {children}
    </IntlProvider>
  )
}

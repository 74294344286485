import React, { ReactNode, useRef } from 'react'
import {
  elementHeight,
  elementY,
  getElementOffset,
  observe,
  resizeEvent,
  rootElement,
  scrollY,
  subscribe,
  useObserver,
  viewportHeight,
  viewportY,
} from 'react-ui-observer'
import styled, { css } from 'styled-components'

import { GradientCanvas } from './GradientCanvas'

const eventHandler = (onChange, event) => {
  window.addEventListener(event, onChange)
  return () => {
    window.removeEventListener(event, onChange)
  }
}

export const event = eventName => subscribe(eventName, eventHandler)

export const globalElement = (selector, deps = []) =>
  observe(...deps, () => document.querySelector(selector))

type GradientSwitcherProps = {
  id?: string
  children?: ReactNode
}

type StickyProps = {
  atTop?: boolean
  atBottom?: boolean
}

const isiPhone = (userAgent => {
  const iOS = /iPhone OS (\d+)/.exec(userAgent)
  return iOS && parseInt(iOS[1]) < 11
})(typeof window === 'undefined' ? '' : window.navigator.userAgent)

const Container = styled.div`
  position: relative;
`

const Sticky = styled.div<StickyProps>`
  position: fixed;
  height: ${isiPhone ? '100vh' : '100%'};
  max-height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  ${props =>
    (props.atTop || props.atBottom) &&
    css`
      position: absolute;
    `};

  ${props =>
    props.atBottom &&
    css`
      bottom: 0;
    `};

  ${props =>
    props.atBottom &&
    !props.atTop &&
    css`
      top: auto;
    `};
`

const navigationHeight = elementHeight(
  globalElement('[data-navigation]', [event('bl:load')])
)

// Collect the sets of elements and gradient details we'll be supporting.
const gradients = observe(
  rootElement(),
  navigationHeight,
  event('bl:updategradient'),
  (root, navigationHeight) => {
    return Array.from(root.querySelectorAll('[data-gradient]')).reduce(
      (gradients: any, element: any) => {
        // Some gradient switchers want to start white before triggering the first section.
        if (element.getAttribute('data-gradient-starts-white')) {
          gradients.unshift({
            name: 'white',
            trigger: navigationHeight,
          })
        }

        gradients.push({
          element,
          name: element.getAttribute('data-gradient'),
          offset: element.getAttribute('data-gradient-offset') || '0.5',
          trigger: element.getAttribute('data-gradient-trigger'),
        })

        return gradients
      },
      []
    )
  }
)

// Figure out the trigger points for each gradient.
const sectionTriggers = observe(
  gradients,
  viewportHeight(),
  resizeEvent(),
  (gradients, viewportHeight) => {
    let lastTrigger = 0
    return Array.from(gradients).map(({ element, offset, trigger }: any) => {
      // Measure trigger from layout.
      if (trigger == null) {
        const top = getElementOffset(element).top
        const viewportOffset = viewportHeight * (1 - offset)
        trigger = top - viewportOffset
      }

      // Triggers should be in dom order.
      lastTrigger = Math.max(lastTrigger, trigger)
      return lastTrigger
    })
  }
)

// Gradient component only needs the gradient names.
const gradientNames = observe(gradients, gradients =>
  gradients.map(gradient => gradient.name)
)

const activeSectionIndex = observe(
  sectionTriggers,
  scrollY(),
  (sectionTriggers, scrollY) => {
    let index = 1
    const length = sectionTriggers.length
    while (index < length && sectionTriggers[index] < scrollY) {
      index++
    }
    return index - 1
  }
)

const atBottom = observe(
  elementY(1, undefined, [resizeEvent(), event('bl:layout')]),
  viewportY(1),
  (containerBottom, viewportBottom) => viewportBottom > containerBottom
)

const atTop = observe(
  elementY(0),
  viewportY(0),
  (containerTop, viewportTop) => viewportTop < containerTop
)

const inView = observe(
  elementY(0),
  viewportY(1),
  elementY(1),
  viewportY(0),
  (elementTop, viewportBottom, elementBottom, viewportTop) =>
    elementTop < viewportBottom && elementBottom > viewportTop
)

const gradientState = observe(
  gradientNames,
  activeSectionIndex,
  atBottom,
  atTop,
  inView,
  (gradientNames, activeSectionIndex, atBottom, atTop, inView) => ({
    gradientNames,
    gradient: gradientNames[activeSectionIndex],
    atBottom,
    atTop,
    inView,
  })
)

export const GradientSwitcher = ({ children, id }: GradientSwitcherProps) => {
  const rootRef = useRef(null)

  const state: any = useObserver(gradientState, { rootElementRef: rootRef })

  return (
    <Container
      ref={rootRef}
      id={id}
      data-gradient-container={state ? true : undefined}
      data-section-container
    >
      {!!state && (
        <Sticky atBottom={state.atBottom} atTop={state.atTop}>
          {state.gradient && (
            <GradientCanvas
              containerId={id}
              gradients={state.gradientNames}
              gradient={state.gradient}
              paused={!state.inView}
            />
          )}
        </Sticky>
      )}
      {children}
    </Container>
  )
}

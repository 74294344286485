import React from 'react'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IGeneralPage } from 'bl-common/src/generated/contentful'
import { Section } from 'bl-common/src/units/Section/Section'

import { BreadcrumbLink } from '../../../BreadcrumbLink'
import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import SectionsRenderer from '../../SectionsRenderer'
import { ContentTemplateProps } from '../types'

const Article = ({
  content,
  metadata,
  config,
}: ContentTemplateProps<IGeneralPage>) => {
  const { title, multilineSubtitle, sections } = content.fields

  return (
    <Page>
      <Metadata metadata={metadata} title={title} />
      <Section top={{ xs: 5, md: 5 }}>
        <BreadcrumbLink top={2} />
        <Type
          preset="headlineLarge"
          bottom={multilineSubtitle ? { xs: 2, md: 2 } : {}}
        >
          {title}
        </Type>
        {multilineSubtitle && (
          <Type preset="subtitle" maxWidth={820} multiline>
            {multilineSubtitle}
          </Type>
        )}
      </Section>
      <SectionsRenderer sections={sections} pageConfig={config} />
    </Page>
  )
}

export default Article

import React, { useContext, useEffect, useState } from 'react'
import { Asset } from 'contentful'
import {
  CarouselContext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel'
// TODO fix not being able to import: https://github.com/vercel/next.js/issues/19936
// import 'pure-react-carousel/dist/react-carousel.es.css'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import { CarouselModal } from '../../units/CarouselModal'
import { media } from '../../utils/media'
import { ContentfulImage } from '../ContentfulImage'

export const Bars = styled(DotGroup)<{ $dotColor?: string }>`
  position: absolute;
  bottom: 24px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;

  > button {
    width: 24px;
    height: 4px;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      height: 4px;
      width: 100%;
      background: white;

      ${media.md(css`
        bottom: 25%;
      `)}
    }
  }

  > .carousel__dot {
    /* TODO ask designer about this animation */
    -webkit-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
    -o-transition: opacity 0.5s, background-color 0.5s, width 0.5s;
    transition: opacity 0.5s, background-color 0.5s, width 0.5s;
    -webkit-transition-delay: 0.5s, 0.5s, 0s;
    -o-transition-delay: 0.5s, 0.5s, 0s;
    transition-delay: 0.5s, 0.5s, 0s;

    -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  > .carousel__dot--selected {
    width: 50px;
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;

    &:after {
      opacity: 1;
      background: ${props => props.$dotColor ?? colors.blueOnDark};
    }
  }

  > .carousel__dot + .carousel__dot {
    margin-left: 12px;
  }
`

type HotelCardCarouselProps = { images: Asset[]; dotColor?: string }

export const CarouselSlider = ({ images }: HotelCardCarouselProps) => {
  const [showModal, setShowModal] = useState(false)

  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext?.state?.currentSlide || 0
  )
  const checkForDragRef = React.useRef(0)

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  const mouseDownCoords = e => {
    checkForDragRef.current = e.clientX
  }
  const clickOrDrag = e => {
    const mouseUp = e.clientX
    if (
      mouseUp < checkForDragRef.current + 5 &&
      mouseUp > checkForDragRef.current - 5
    ) {
      setShowModal(true)
    }
  }
  return (
    <>
      <Slider
        onMouseDown={e => mouseDownCoords(e)}
        onMouseUp={e => clickOrDrag(e)}
      >
        {images.map((image, index) => (
          <Slide index={index} key={`${image.fields.title}-${index}`}>
            <ContentfulImage
              image={image}
              sizes="(min-width: 75em) 50vw, (min-width: 48em) 100vw, 100vw"
              priority={index === 0}
            />
          </Slide>
        ))}
      </Slider>
      <CarouselModal
        show={showModal}
        onHide={() => setShowModal(false)}
        currentSlide={currentSlide}
        carousel={images}
      />
    </>
  )
}

export const HotelCardCarousel = ({
  images,
  dotColor,
}: HotelCardCarouselProps) => {
  const { mediaMd, mediaMlg } = useBreakpoints()

  return (
    <CarouselProvider
      naturalSlideHeight={mediaMlg ? 650 : mediaMd ? 350 : 230}
      naturalSlideWidth={mediaMlg ? 550 : 350}
      totalSlides={images.length}
    >
      <CarouselSlider images={images} />
      <Bars $dotColor={dotColor} />
    </CarouselProvider>
  )
}

import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { pb, pt } from 'styled-components-spacing'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { modularScale } from 'bl-common/src/constants/sizes'
import { IGradientFields } from 'bl-common/src/generated/contentful'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'

export const Outer = styled.div`
  padding: 32px 0 60px;
  background-image: linear-gradient(
    38deg,
    ${rgba('#C4D4DF', 0.36)} 10%,
    ${rgba('#EFEEEA', 0.36)} 100%
  );

  ${media.md(css`
    padding: ${between(80 / modularScale, 80)} 0;
  `)};

  @media (min-width: ${breakpoints.md}px) and (orientation: portrait) {
    padding: ${between(80 / modularScale, 80)} ${between(50 / modularScale, 50)};
  }
`

export const Container = styled.div`
  position: relative;
  margin: auto;
  width: calc(100% - 32px);

  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    display: flex;
    flex-wrap: wrap;
    min-height: 860px;
    width: calc(100% - 160px);
  }
`

export const Content = styled.div`
  background: ${colors.white};
  flex: 1 1 65%;
`

export const MapContent = styled(Content)`
  background: #b9d9e4;
  ${mediaMax.md(css`
    height: 350px;
  `)};
`

export const Sidebar = styled.aside<{
  textColor?: 'dark' | 'light'
  isFlex?: boolean
  gradient?: { fields: IGradientFields & { gradient?: string } }
  image?: string
  withBorder?: boolean
}>`
  background-image: linear-gradient(-134deg, #5b839b 0%, #50a7ba 100%);
  color: ${({ textColor }) =>
    textColor === 'dark' ? colors.dark : colors.white};
  min-height: 250px;
  padding: 0 ${between(41 / modularScale, 41)};

  ${({ isFlex }) => isFlex && 'display: flex'};

  ${({ gradient }) =>
    gradient &&
    css`
      background: linear-gradient(
        ${gradient.fields.degrees || '-134deg'},
        ${gradient.fields.color1},
        ${gradient.fields.color2}
      );
    `};

  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
    `};

  ${({ theme }) => pb(5, theme)};

  && a {
    color: ${({ textColor }) =>
      textColor === 'dark' ? colors.dark : colors.white};
    font-weight: 400;
  }
  & h1 {
    font-size: 18px;

    ${media.md(css`
      font-size: ${between(24 / modularScale, 24)};
    `)};
  }

  & h1 + * {
    padding-top: 1rem;
  }

  ${media.md(css<{ withBorder?: boolean }>`
    flex: 1 0 30%;
    padding-left: ${({ withBorder }) =>
      !withBorder ? between(41 / modularScale, 41) : '0'};
    padding-right: ${between(41 / modularScale, 41)};

    ${({ theme }) => pt(5, theme)};
  `)};

  ${media.lg(css`
    flex: 1 0 35%;
    min-height: 555px;
  `)};
`

export const SidebarInner = styled.div`
  padding-top: ${between(41 / modularScale, 41)};

  ${media.md(css`
    padding-top: 0;
  `)};
`

import React from 'react'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'

type ContainerProps = {
  hasError?: boolean
}

const Container = styled.div<ContainerProps>`
  display: none;
  position: relative;
  width: 100%;
  border: 1px solid ${colors.errorRed};
  background: ${rgba(colors.errorRed, 0.15)};
  padding: ${({ theme }) => theme.spacing[1]};
  margin: ${({ theme }) => theme.spacing[2]} 0;

  ${({ hasError }) =>
    hasError &&
    css`
      display: block;
    `}
`

export const ErrorMessage = ({ errorMessage = '', error = false }) => (
  <Container hasError={error}>
    <div>
      <Type preset="text">{errorMessage}</Type>
    </div>
  </Container>
)

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
}

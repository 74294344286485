import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { modularScale } from 'bl-common/src/constants/sizes'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

export const Outer = styled.div`
  background-image: linear-gradient(
    38deg,
    ${rgba('#C4D4DF', 0.36)} 10%,
    ${rgba('#EFEEEA', 0.36)} 100%
  );

  ${media.md(css`
    padding: ${between(80 / modularScale, 80)} 0;
  `)};

  @media (min-width: ${breakpoints.md}px) and (orientation: portrait) {
    padding: ${between(80 / modularScale, 80)} ${between(50 / modularScale, 50)};
  }
`

export const Container = styled.div`
  box-shadow: 0 25px 25px -25px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-height: 60vh;
  width: 100%;
  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: calc(100% - 160px);
  }
`

export const Content = styled.div`
  align-items: flex-start;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  flex: 1 1 65%;
  padding: 32px;

  ${media.md(css`
    padding: ${between(92 / modularScale, 92)}
      ${between(108 / modularScale, 108)} ${between(180 / modularScale, 180)};
  `)};
`

export const Sidebar = styled.aside<{ image: string }>`
  position: relative;
  flex: 1 0 35%;
  display: none;
  background-image: linear-gradient(-134deg, #5b839b 0%, #50a7ba 100%);
  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    display: block;
  }
  &::before {
    opacity: 0.5;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    ${({ image }) => css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;
    `};
  }
`

import React from 'react'
import ErrorPage from 'next/error'

import { IMetadata, IPage } from 'bl-common/src/generated/contentful'

import { withTransition } from '../../utils/withTransition'
import * as templates from './templates'
import { ContentTemplateComponent } from './templates/types'

type TemplatePageProps = { page: IPage }

const TemplatePage = ({ page }: TemplatePageProps) => {
  if (!page) {
    return <ErrorPage statusCode={404} />
  }

  const { content, paths, metadata, config } = page.fields
  const type = content?.sys?.contentType?.sys?.id

  if (!type || !templates[type]) {
    return <ErrorPage statusCode={404} />
  }

  let newMetadata: Partial<IMetadata> = metadata

  if (paths.length > 1) {
    if (!newMetadata) {
      newMetadata = { fields: {} }
    }
    newMetadata.fields.canonicalUrl =
      newMetadata.fields.canonicalUrl || `${paths[0]}`
  }

  const ContentTemplate: ContentTemplateComponent = templates[type]

  return (
    <ContentTemplate
      page={page}
      metadata={newMetadata}
      content={content}
      config={config}
    />
  )
}

export default withTransition(TemplatePage)

import React, { Component, createRef } from 'react'
import Granim from 'granim'
import styled from 'styled-components'

import { durations } from 'bl-common/src/constants/durations'
import { gradients } from 'bl-common/src/constants/gradients'

type GradientCanvasProps = {
  gradient: any // PropTypes.oneOf(Object.keys(gradients))
  gradients?: any
  containerId?: string
  paused?: boolean
  gradientIsActive?: boolean
  direction?: 'diagonal' | 'left-right' | 'top-bottom' | 'radial'
  speed?: number
  opacity?: number
  zIndex?: number
}

type CanvasProps = {
  zIndex?: number
}

const mirror = arr => [[...arr], [...arr.reverse()]]

const DEFAULT_GRADIENT = [['#ffffff', '#ffffff']]
const DEFAULT_GRADIENT_DARK = [['#454647', '#454647']]

const Canvas = styled.canvas<CanvasProps>`
  bottom: -1px;
  height: 100%;
  left: -1px;
  margin: auto;
  position: absolute;
  right: -1px;
  top: -1px;
  width: 100%;
  z-index: ${props => props.zIndex};
`

export class GradientCanvas extends Component<GradientCanvasProps> {
  canvasRef = createRef()
  granim = undefined

  componentDidMount() {
    const {
      gradient,
      direction = 'diagonal',
      paused,
      opacity = 1,
      containerId,
    } = this.props

    this.granim = new Granim({
      name: 'gradient',
      element: this.canvasRef.current,
      elToSetClassOn: containerId && `#${containerId}`,
      stateTransitionSpeed: durations.gradient,
      direction,
      opacity: [opacity, opacity],
      defaultStateName: gradient,
      states: this.updateStates(),
    })

    if (paused) {
      this.granim.pause()
    }
  }

  componentDidUpdate(prevProps) {
    const { gradient, paused } = this.props
    this.updateStates(true)
    if (this.granim && prevProps.gradient !== gradient) {
      this.granim.changeState(gradient)
    }
    if (this.granim && prevProps.paused !== paused) {
      if (paused) {
        this.granim.pause()
      } else {
        this.granim.play()
      }
    }
  }

  componentWillUnmount() {
    if (this.granim) {
      // Clean up global class names.
      if (this.granim.elToSetClassOn === 'body') {
        const bodyClass = document.body.classList
        bodyClass.remove('gradient-dark')
        bodyClass.remove('gradient-light')
      }

      this.granim.destroy()
    }
  }

  updateStates = (mutateGranim = false) => {
    const { gradient, speed = 4000 } = this.props
    const defaultGradient = document.body.classList.contains('t-dark')
      ? DEFAULT_GRADIENT_DARK
      : DEFAULT_GRADIENT
    const gradientNames = this.props.gradients || [gradient]
    const states = gradientNames.reduce((states, gradient) => {
      if (gradient === 'white') {
        states[gradient] = {
          gradients: defaultGradient,
          loop: false,
        }
      } else {
        states[gradient] = {
          gradients: gradients[gradient]
            ? mirror(gradients[gradient])
            : defaultGradient,
          transitionSpeed: speed,
        }
      }
      return states
    }, {})

    if (mutateGranim) {
      this.granim.states = states
    } else {
      return states
    }
  }

  render() {
    const { zIndex = -1, gradientIsActive = true } = this.props

    return (
      <Canvas
        ref={this.canvasRef as React.RefObject<HTMLCanvasElement>}
        zIndex={zIndex}
        style={{ opacity: gradientIsActive ? 1 : 0 }}
      />
    )
  }
}

import React from 'react'
import { useIntl } from 'react-intl'
import { globalMessages } from '@messages/globalMessages'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { modularScale } from 'bl-common/src/constants/sizes'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

const Content = styled.div`
  padding-bottom: ${between(41 / modularScale, 41)};

  ${media.md(css`
    padding: 0 ${between(41 / modularScale, 41)}
      ${between(41 / modularScale, 41)};
  `)};

  && a {
    font-weight: 600;
    color: ${colors.white};
  }
`

// Extract phone number from text just in case the text contains other characters
const getPhoneFromText = (input: string) => {
  return input?.replace(/\D/g, '')
}

const SupportCenter = () => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { formatMessage } = useIntl()

  return (
    <Content>
      <Type preset="headlineMedium" bottom={{ xs: 1 }}>
        {formatMessage(globalMessages.supportCenterTitle)}
      </Type>
      <Type size={{ xs: 14, md: 24 }} bottom={{ xs: 1 }} weight="light">
        {formatMessage(globalMessages.supportCenterSubtitle)}
      </Type>
      <Type size={{ xs: 16, md: 24 }}>
        <Link
          to={`tel:+${getPhoneFromText(
            formatMessage(globalMessages.supportCenterPhone)
          )}`}
        >
          {formatMessage(globalMessages.supportCenterPhone)}
        </Link>
      </Type>
    </Content>
  )
}

export default SupportCenter

import { defineMessages } from 'react-intl'

export const globalMessages = defineMessages({
  selectLanguageModalTitle: {
    id: 'website:global.selectLanguageTitle',
    defaultMessage: 'Select language',
    description: 'Global - language: Title of the language selection modal',
  },
  selectLanguageModalSubtitle: {
    id: 'website:global.selectLanguageSubtitle',
    defaultMessage:
      'You seem to be located outside of Iceland. Would you like to change to English or continue with Icelandic?',
    description: 'Global - language: Subtitle of the language selection modal',
  },
  supportCenterTitle: {
    id: 'website:global.supportCenterTitle',
    defaultMessage: 'Support center',
    description: 'Global - support center: Title of the Support center page',
  },
  supportCenterSubtitle: {
    id: 'website:global.supportCenterSubtitle',
    defaultMessage: 'Our service center is open from 9am - 7pm every day',
    description: 'Global - support center: Subtitle of the Support center page',
  },
  supportCenterPhone: {
    id: 'website:global.supportCenterPhone',
    defaultMessage: '+354 420 8800',
    description: 'Global - support center: Phone number of the Support center',
  },
})

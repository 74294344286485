import { useEffect, useState } from 'react'
import {
  GetStaticPaths,
  GetStaticPropsContext,
  InferGetStaticPropsType,
} from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import get from 'lodash/get'

import { IPageFields } from 'bl-common/src/generated/contentful'
import { IntlProviderWrapper } from 'bl-localization/src/IntlProviderWrapper'
import { triggerPageView } from 'bl-utils/src/analytics/events'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { SelectLanguageModal } from 'components/SelectLanguageModal'
import { getUserLocationInfo } from 'services/locationService'
import { getCurrentEnvironment } from 'utils/environment'

import Portals, { fetchPortals, portalTitles } from '../components/Portals'
import TemplatePage from '../components/TemplatePage'
import { getEntriesWrapper } from '../services/contentfulClient'

const CommandPaletteWrapper = dynamic(() => import('components/CommandPalette'))

const environment = getCurrentEnvironment()

const slugToType = {
  en: {
    explore: 'explore',
    'day-visit': 'dayVisit',
    hotels: 'accommodation',
    restaurant: 'restaurant',
    dining: 'restaurant',
    more: 'more',
  },

  is: {
    heimsokn: 'dayVisit',
    gisting: 'accommodation',
    matur: 'restaurant',
    'boka-mida': 'explore',
    more: 'more',
  },
}

const portalRoutes = {
  is: [
    { params: { slug: ['heimsokn'] } },
    { params: { slug: ['gisting'] } },
    { params: { slug: ['matur'] } },
    { params: { slug: ['more'] } },
    { params: { slug: ['boka-mida'] } },
  ],
  en: [
    { params: { slug: ['explore'] } },
    { params: { slug: ['day-visit'] } },
    { params: { slug: ['hotels'] } },
    { params: { slug: ['restaurant'] } },
    { params: { slug: ['more'] } },
  ],
}

const hasFormSection = (item: any) => {
  if (!Array.isArray(item.fields.content?.fields.sections)) {
    // This Page type does not have sections.
    return false
  }

  for (const section of item.fields.content.fields.sections) {
    const contentTypeId = section.sys.contentType.sys.id
    if (contentTypeId === 'formSection') {
      return true
    }
  }
  return false
}

export const CreateTemplatePage = (locale: 'is' | 'en') => {
  const getStaticPaths: GetStaticPaths = async () => {
    const environment = getCurrentEnvironment()

    if (environment === 'development') {
      return { paths: [], fallback: 'blocking' }
    }

    const entries = await getEntriesWrapper<IPageFields>({
      content_type: 'page',
      include: 2,
      limit: 1000,
      locale,
    })
    const pathsFromEntries = entries.items
      .filter(item => Array.isArray(item.fields.paths) && !hasFormSection(item)) // Filter entries with valid paths and without forms
      .map(item =>
        item.fields.paths.map(path => {
          if (path.startsWith('/is/') || path === '/is') {
            path = path.substr(3)
          }
          const slug = path.split('/').filter(Boolean)
          return { params: { slug } }
        })
      )
      .flat() // Flatten the array of arrays to a single array of paths

    // Concatenate with portalRoutes
    const paths = pathsFromEntries.concat(portalRoutes[locale])

    return { paths, fallback: 'blocking' }
  }

  const getStaticProps = async (context: GetStaticPropsContext) => {
    const { params } = context

    if (
      params &&
      Array.isArray(params.slug) &&
      params.slug.length === 1 &&
      slugToType[locale][params.slug[0]]
    ) {
      const portal = params.slug[0]
      const type = slugToType[locale][portal]
      const portals = await fetchPortals(type, locale)
      const content = portalTitles[locale][type] || null
      return { props: { type: 'portal', portals, content } }
    }

    let path = '/'

    if (Array.isArray(params?.slug)) {
      path += params.slug.join('/')
    }

    if (locale === 'is') {
      if (path === '/') {
        path = '/is'
      } else {
        path = '/is' + path
      }
    }

    const entries = await getEntriesWrapper<IPageFields>({
      content_type: 'page',
      include: 10,
      'fields.paths[in]': path,
      locale,
    })

    let translations: Record<string, string> = {}

    try {
      const namespaces = ['spaBooking', 'sharedFlows', 'website']
      const localizationData = await getEntriesWrapper({
        content_type: 'localization',
        select: 'fields.strings',
        'fields.namespace[in]': namespaces.join(','),
        locale,
      })

      translations = localizationData.items.reduce((acc, item) => {
        return {
          ...acc,
          ...get(item, 'fields.strings', {}),
        }
      }, {})
    } catch (e) {
      sentryLogging({
        error: new Error('Error fetching translations from contentful', e),
      })
    }

    const page = entries.items[0]

    if (!page) {
      return { props: {}, notFound: true }
    }

    return { props: { type: 'page', page, translations }, revalidate: 10 }
  }

  const Render = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
    const [languagePopupOpen, setLanguagePopupOpen] = useState(false)
    const router = useRouter()
    const { asPath, pathname, query } = router

    useEffect(() => {
      triggerPageView({
        language: locale,
        currencyCode: locale === 'en' ? 'EUR' : 'ISK',
      })
    }, [])

    useEffect(() => {
      // Google my business link needs to be to /is.
      // this checks if query includes utm_source=google
      // and displays the language popup if the user is not in Iceland.
      if (asPath.includes('/is') && query.utm_source === 'google') {
        getUserLocationInfo().then(location => {
          const countryCode = location?.countryCode
          if (countryCode && countryCode !== 'IS') {
            setLanguagePopupOpen(true)
            // Remove the utm_source=gmb query parameter so that the user
            // doesn't get the popup again if they navigate to another page.
            delete query.utm_source
            router.replace({ pathname, query }, undefined, {
              shallow: true,
            })
          }
        })
      }
    }, [asPath, query, setLanguagePopupOpen])

    if (props.type === 'portal') {
      const { portals, content } = props
      return <Portals content={content} portals={portals} />
    }

    const { page } = props

    return (
      <>
        <IntlProviderWrapper
          locale={locale}
          defaultLocale={locale}
          messages={props.translations}
        >
          <TemplatePage page={page} />
          <SelectLanguageModal
            show={languagePopupOpen}
            onHide={() => setLanguagePopupOpen(false)}
          />
        </IntlProviderWrapper>
        {environment === 'development' && <CommandPaletteWrapper page={page} />}
      </>
    )
  }

  return { Page: Render, getStaticProps, getStaticPaths }
}

const { Page, ...rest } = CreateTemplatePage('en')

export default Page
export const getStaticProps = rest.getStaticProps
export const getStaticPaths = rest.getStaticPaths

import get from 'lodash/get'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { IPageRoom } from 'bl-common/src/generated/contentful'
import { CardCarousel } from 'bl-common/src/units/CardCarousel/CardCarousel'
import { CommonAreasGallery } from 'bl-common/src/units/Gallery/CommonAreasGallery'
import { RoomGallery } from 'bl-common/src/units/Gallery/RoomGallery'
import { LightBoxProvider } from 'bl-common/src/units/LightBox/LightBoxProvider'
import { Section } from 'bl-common/src/units/Section/Section'

import { cardFormatters } from '../../../../utils/formatters'
import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import { RenderComponent } from '../../../RenderComponent'
import HeaderSection from '../../sections/HeaderSection'
import HeroSection from '../../sections/HeroSection'
import HeroWithContent from '../../sections/HeroWithContentSection'
import RoomOverviewSection from '../../sections/RoomOverviewSection'
import { ContentTemplateProps } from '../types'

const RelatedRoomsCarousel = ({ rooms, scrollKey }) => (
  <RenderComponent componentData={rooms} contentType="carousel">
    {component => {
      // only allowed to use suiteCards
      const cards = get(component, 'fields.cards', [])

      const filteredCards = cards.filter(
        card => get(card, 'sys.contentType.sys.id') === 'suiteCard'
      )

      const formattedCards = filteredCards.map(card => {
        const formatter = cardFormatters[get(card, 'sys.contentType.sys.id')]
        return formatter && formatter(card)
      })

      return (
        <CardCarousel
          cards={formattedCards}
          id={scrollKey}
          minItemWidthFrom={175}
          minItemWidthTo={236}
        />
      )
    }}
  </RenderComponent>
)

const RelatedRoomsSection = ({ rooms, heading }) => (
  <div>
    <Section top={{ xs: 2, md: 4 }} bottom={{ xs: 2, md: 2 }}>
      <Type
        as="h3"
        preset="headlineMedium"
        bottom={{
          xs: 1,
          md: 2,
        }}
      >
        {heading}
      </Type>
      <RelatedRoomsCarousel rooms={rooms} scrollKey="relatedRoomsCarousel" />
    </Section>
  </div>
)

const Hero = ({ hero }) =>
  hero.sys.contentType.sys.id === 'sectionHeroWithContent' ? (
    <HeroWithContent section={hero} />
  ) : (
    <HeroSection
      fullSizeImage={get(hero, 'fields.fullSizeImage')}
      mobileCroppedImage={get(hero, 'fields.mobileCroppedImage')}
      video={get(hero, 'fields.video')}
      offsetDirection={get(hero, 'fields.offsetDirection')}
      backgroundContrast={get(hero, 'fields.backgroundContrast')}
      frameGradient={get(hero, 'fields.frameGradient')}
      config={get(hero, 'config')}
    />
  )

const RoomPage = ({
  content: {
    fields: {
      header,
      hero,
      roomOverview,
      roomGallery,
      commonAreasGallery,
      relatedRoomsCarousel,
      relatedRoomsHeading = 'Related rooms',
      customFooter,
    },
  },
  metadata,
  config,
}: ContentTemplateProps<IPageRoom>) => {
  return (
    <Page footer={customFooter} config={config}>
      <LightBoxProvider>
        <HeaderSection
          tagline={get(header, 'fields.tagline')}
          heading={get(header, 'fields.heading')}
          multilineSubheading={get(header, 'fields.multilineSubheading')}
          disclaimer={get(header, 'fields.disclaimer')}
          showScrollArrow={get(header, 'fields.showScrollArrow')}
          showBreadcrumbLink={get(header, 'fields.showBreadcrumbLink')}
          config={get(header, 'fields.config')}
        />
        {hero && <Hero hero={hero} />}
        {roomOverview && (
          <RoomOverviewSection
            roomTitle={get(roomOverview, 'fields.roomTitle')}
            roomDescription={get(roomOverview, 'fields.roomDescription')}
            roomQuickView={get(roomOverview, 'fields.roomQuickView')}
            roomInclusions={get(roomOverview, 'fields.roomInclusions')}
            roomInclusionsFinePrint={get(
              roomOverview,
              'fields.roomInclusionsFinePrint'
            )}
          />
        )}
        {roomGallery && (
          <Section top={{ xs: 4, md: 6 }} bottom={{ xs: 2, md: 4 }}>
            <RoomGallery
              items={get(roomGallery, 'fields.items')}
              heading={get(roomGallery, 'fields.heading')}
              multilineSubheading={get(
                roomGallery,
                'fields.multilineSubheading'
              )}
            />
          </Section>
        )}
        {commonAreasGallery && (
          <Section top={{ xs: 2, md: 4 }} bottom={{ xs: 2, md: 2 }}>
            <CommonAreasGallery
              heading={get(commonAreasGallery, 'fields.heading')}
              multilineSubheading={get(
                commonAreasGallery,
                'fields.multilineSubheading'
              )}
              tallImage1={get(commonAreasGallery, 'fields.tallImage1')}
              tallImage2={get(commonAreasGallery, 'fields.tallImage2')}
              squareImage1={get(commonAreasGallery, 'fields.squareImage1')}
              squareImage2={get(commonAreasGallery, 'fields.squareImage2')}
              fullWidthImage={get(commonAreasGallery, 'fields.fullWidthImage')}
            />
          </Section>
        )}
        {relatedRoomsCarousel && (
          <RelatedRoomsSection
            rooms={relatedRoomsCarousel}
            heading={relatedRoomsHeading}
          />
        )}
      </LightBoxProvider>
      <Metadata metadata={metadata} />
    </Page>
  )
}

export default RoomPage

import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { topicLineSize } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { media } from 'bl-common/src/utils/media'

type LineProps = {
  left?: any
  right?: any
}

const TopLine = styled.div`
  display: none;
  padding-bottom: ${topicLineSize + 1}px;
  position: sticky;
  top: -1px;
  width: 100%;

  ${media.md(css`
    background: ${colors.white};
    display: block;
    z-index: ${zIndex.above2};
  `)};
`

const Line = styled.div<LineProps>`
  position: absolute;
  width: ${topicLineSize}px;
  height: 100%;
  background: ${colors.white};
  display: none;

  ${media.md(css`
    display: block;
    background: ${colors.white};
    z-index: ${zIndex.above2};
  `)};

  ${props =>
    props.right &&
    css`
      right: 0;
    `};
  ${props =>
    props.left &&
    css`
      left: 0;
    `};
`

const Container = styled.div`
  overflow: hidden;
  ${media.md(css`
    margin-bottom: ${topicLineSize}px;
    margin-top: -${topicLineSize}px;
    position: relative;
    padding-top: 0;
  `)};
`

const Content = styled.div`
  position: relative;
`

const TopicContainer = ({ children }) => (
  <Container>
    <TopLine />
    <Line left />
    <Line right />

    <Content>{children}</Content>
  </Container>
)

export default TopicContainer

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { pb, pt } from 'styled-components-spacing'

import { colors } from 'bl-common/src/constants/colors'
import {
  gridOffsetFrom,
  gridOffsetTo,
  topicLineSize,
} from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { ScrollArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Hero } from 'bl-common/src/units/Hero'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

const Content = styled.div`
  color: ${colors.white};
  flex: 1;
  height: 100vh;
  position: relative;
  z-index: ${zIndex.above};
  ${media.md(css`
    height: calc(100vh - ${topicLineSize * 2}px);
  `)};
  ${mixins.betweenProperty('padding-left', gridOffsetFrom, gridOffsetTo)};
  ${mixins.betweenProperty('padding-right', gridOffsetFrom, gridOffsetTo)};

  ${({ theme }) => pt(8, theme)};
  ${({ theme }) => pb(8, theme)};
`

const Spacing = styled.div`
  ${media.md(css`
    padding-bottom: ${topicLineSize}px;
  `)};
`

export const TopicHero = ({
  image,
  mobileImage,
  children,
  backgroundContrast,
}) => (
  <Spacing>
    <Hero
      image={image}
      mobileImage={mobileImage}
      size="content"
      shouldAnimate
      backgroundContrast={backgroundContrast}
    >
      <Content>
        {children}
        <ScrollArrow bottom="30%" />
      </Content>
    </Hero>
  </Spacing>
)

TopicHero.defaultProps = {
  image: null,
  backgroundContrast: 0.333,
}

TopicHero.propTypes = {
  image: PropTypes.any.isRequired,
}
